import { JobStatusInterface } from '@actassa/api';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseApiService } from './base.api.service';

@Injectable({
    providedIn: 'root',
})
export class JobStatusesApiService {

    constructor(
        private readonly baseApiService: BaseApiService,
    ) { }

    public getAll$(): Observable<Array<JobStatusInterface>> {
        return this.baseApiService.get$<Array<JobStatusInterface>>(
            'job-api/job-status',
            { defaultValue: [] },
        );
    }

}
