import { Environment, ENVIRONMENT_TOKEN, StoreWrapperInterface, STORE_WRAPPER_TOKEN, UserInterface } from '@actassa/api';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Navigate } from '@ngxs/router-plugin';
import { Actions, ofActionDispatched } from '@ngxs/store';
import { isArray, isEmpty, isEqual, isString } from 'lodash-es';
import moment from 'moment';
import { EMPTY, merge, Observable } from 'rxjs';
import { catchError, distinctUntilChanged, mapTo, switchMap, take, tap } from 'rxjs/operators';
import { v4 as uuid } from 'uuid';
import { GetInspections } from '../+state/actions/get-inspections';
import { LoadInspections } from '../+state/actions/load-inspections';
import { PickInspection } from '../+state/actions/pick-inspection';
import { SaveInspection } from '../+state/actions/save-inspection';
import { UpdateInspection } from '../+state/actions/update-inspection';
import { RoutesDictionary } from '../dictionaries/routes.dictionary';
import { checkDependency } from '../helpers/comparator-functions.helper';
import { paramReplacer } from '../helpers/param-replacer.helper';
import { initSubstitutionStream } from '../helpers/substitution-stream.helper';
import { InspectionInterface } from '../interfaces/inspection.interface';
import { TemplateInterface } from '../interfaces/template.interface';
import { LogicOperator, UserAction } from '../interfaces/user-action.interface';
import { TemplateControl } from './template-control';
import { UserActionService } from './user-action.service';

interface ResultInterface {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    data?: any;
    message: string;
    status: ErrorStatus;
}

enum ErrorStatus {
    // eslint-disable-next-line @typescript-eslint/naming-convention
    OK = 'ok',
    // eslint-disable-next-line @typescript-eslint/naming-convention
    ERROR = 'error',
}

@Injectable({
    providedIn: 'root',
})
export class InspectionsService {
    constructor(
        @Inject(ENVIRONMENT_TOKEN) private readonly environment: Environment,
        @Inject(STORE_WRAPPER_TOKEN) private storeWrapper: StoreWrapperInterface,
        private readonly actions$: Actions,
        private readonly http: HttpClient,
        private readonly userActionService: UserActionService,
    ) { }

    public init$(): Observable<void> {
        return merge(
            this.actions$
                .pipe(
                    ofActionDispatched(GetInspections),
                    tap(() => console.log('GetInspections')),
                    tap(() => this.storeWrapper.loadingStart()),
                    switchMap(() => this.load$()),
                    tap(() => this.storeWrapper.loadingEnd()),
                ),
            this.actions$
                .pipe(
                    ofActionDispatched(SaveInspection, UpdateInspection),
                    distinctUntilChanged(isEqual),
                    switchMap(({ inspection }: SaveInspection) => this.save$(inspection)),
                ),
        );
    }

    public batchInspectionCreate$(inspection: InspectionInterface, isFinished: boolean): Observable<unknown> {
        const form = this.createFormGroup(inspection);
        const preparedInspection = this.prepare(inspection, form, isFinished);

        if (preparedInspection.isFinished) {
            this.handlePostFinishActions(preparedInspection);

            return this.save$(preparedInspection).pipe(take(1));
        }

        this.saveInspection(preparedInspection);
        this.pickInspection(preparedInspection.id);

        return this.storeWrapper.baseUrl$
            .pipe(
                take(1),
                tap((baseUrl) => {
                    const localUrl = RoutesDictionary.DATA_COLLECTION_INSPECTION;

                    this.gotoInspection(`${baseUrl}/${localUrl}`);
                }),
            );
    }

    public mapTemplateInspection(
        template: TemplateInterface,
        user: UserInterface,
    ): InspectionInterface {
        const currentMoment = moment().utc();
        const currentTimestamp = currentMoment.valueOf();
        const currentDateTime = currentMoment.toISOString();

        return {
            ...template,
            appId: this.environment.appId,
            createdAt: currentTimestamp,
            createdAtFormatted: currentDateTime,
            modifiedAt: currentTimestamp,
            modifiedAtFormatted: currentDateTime,
            id: uuid(),
            isFinished: false,
            isDeleted: false,
            mailBodyHtml: paramReplacer(template.mailBodyHtml, user),
            mailBodyText: paramReplacer(template.mailBodyText, user),
            mailSubject: paramReplacer(template.mailSubject, user),
            outputName: paramReplacer(template.outputName, user),
            questions: this.questionsReplacer(template.questions, user),
            taskConfig: this.questionsReplacer(template.taskConfig, user),
            userId: user.id,
            uuid: user.uuid,
        };
    }

    public handlePostFinishActions(inspection: InspectionInterface): void {
        const { postFinishActions, questions } = inspection;

        if (isArray(postFinishActions)) {
            postFinishActions.forEach(action => {
                if (isEmpty(action?.dependencies)) {
                    this.userActionService.handle(action);

                    return;
                }

                const dependencyTopLevelLogic = this.getDependencyTopLevelLogic(action);
                const initialValue = this.getInitialValueOfCondition(action);
                const condition = action.dependencies.reduce((accumulator, dependency) => {
                    const affectedQuestion = questions.find(question => question.key === dependency.key);

                    if (!affectedQuestion) {
                        return accumulator;
                    }

                    if (dependencyTopLevelLogic === LogicOperator.OR) {
                        return accumulator || checkDependency(dependency.value, affectedQuestion.value);
                    }

                    return accumulator && checkDependency(dependency.value, affectedQuestion.value);
                }, initialValue);

                if (condition) {
                    this.userActionService.handle(action);
                }
            });
        }
    }

    public save$(inspection: InspectionInterface): Observable<void> {
        return this.http.patch<ResultInterface>(`${this.environment.apiURL}/inspections`, inspection)
            .pipe(
                // take(1),
                tap((response: ResultInterface) => this.responseMessageHandler(response)),
                catchError((error) => {
                    this.storeWrapper.showToast(error.message);

                    return EMPTY;
                }),
                mapTo(null),
            );
    }

    public createFormGroup({ questions }: InspectionInterface): FormGroup {
        const group = questions.reduce((accum, question) => {
            const { key, required, value, notes, media, tasks } = question;

            if (!isEmpty(notes)) {
                accum[notes.key] = new FormControl(notes.value || '');
            }

            if (!isEmpty(media)) {
                accum[media.key] = new FormControl(media.value || []);
            }

            if (!isEmpty(tasks)) {
                accum[tasks.key] = new FormControl(tasks.value || []);
            }

            accum[key] = required
                ? new FormControl(value || '', Validators.required)
                : new FormControl(value || '');

            return accum;
        }, {});

        return new FormGroup(group);
    }

    public prepare(inspection: InspectionInterface, form: FormGroup, isFinished?: boolean, isDeleted?: boolean): InspectionInterface {
        const formValue = { ...form.value };
        const questions = inspection.questions.map(question => {
            const { media, notes, buttons = [], tasks } = question;
            let value = formValue[question.key];

            if (!value && question.emptyValueTemplate && isFinished) {
                value = initSubstitutionStream(question.emptyValueTemplate, formValue, inspection.questions);
            }

            return {
                ...question,
                value,
                notes: notes ? { key: notes.key, value: formValue[notes.key] } : undefined,
                media: media ? { key: media.key, value: formValue[media.key] } : undefined,
                tasks: tasks ? { key: tasks.key, value: formValue[tasks.key] } : undefined,
                buttons: buttons?.length
                    ? buttons.map(button => {
                        const { addons = [] } = button;

                        return { ...button, addons: addons?.map(addon => ({ ...addon, value: formValue[addon.key] })) };
                    })
                    : undefined,
            };
        });

        const currentMoment = moment().utc();
        const currentTimestamp = currentMoment.valueOf();
        const currentDateTime = currentMoment.toISOString();

        return {
            ...inspection,
            questions: [...questions],
            isFinished: inspection.isFinished ? true : isFinished,
            isDeleted,
            modifiedAt: currentTimestamp,
            modifiedAtFormatted: currentDateTime,
            deletedAt: isDeleted ? currentTimestamp : undefined,
            deletedAtFormatted: isDeleted ? currentDateTime : undefined,
        };
    }

    public load$(): Observable<void> {
        return this.http.get<ResultInterface>(`${this.environment.apiURL}/inspections`)
            .pipe(
                // take(1),
                tap((response: ResultInterface) => {
                    this.responseMessageHandler(response);
                    this.responseDataHandler(response);
                }),
                catchError((error) => {
                    this.storeWrapper.loadingEnd();
                    this.storeWrapper.showToast(error.message);

                    return EMPTY;
                }),
                mapTo(null),
            );
    }

    private responseMessageHandler(response: ResultInterface): void {
        if (!response) {
            this.storeWrapper.showToast('Server error.');

            return;
        }

        const { message } = response;

        this.storeWrapper.showToast(message);
    }

    private responseDataHandler(response: ResultInterface): void {
        if (!response) {
            return;
        }

        const { status, data } = response;

        if (status === ErrorStatus.OK) {
            JSON.stringify(data);

            if (data) {
                this.loadInspections(data);
            }

            return;
        }
    }

    private questionsReplacer(
        questions: Array<TemplateControl<any>>,
        user: UserInterface,
    ): Array<TemplateControl<any>> {
        return questions?.map(question => ({
            ...question,
            label: paramReplacer(question.label, user),
            value: paramReplacer(question.value, user),
            buttons: question.buttons?.length ? question.buttons.map(button => ({
                ...button,
                label: paramReplacer(button.label, user),
                value: paramReplacer(button.value, user),
                addons: button.addons?.length ? button.addons.map(addon => ({
                    ...addon,
                    label: paramReplacer(addon.label, user),
                    value: paramReplacer(addon.value, user),
                })) : undefined,
            })) : undefined,
        }));
    }

    private getDependencyTopLevelLogic({ dependencyTopLevelLogic }: UserAction): LogicOperator {
        if (isString(dependencyTopLevelLogic) && dependencyTopLevelLogic.toUpperCase() === LogicOperator.OR) {
            return LogicOperator.OR;
        }

        return LogicOperator.AND;
    }

    private getInitialValueOfCondition({ dependencyTopLevelLogic }: UserAction): boolean {
        if (isString(dependencyTopLevelLogic) && dependencyTopLevelLogic.toUpperCase() === LogicOperator.OR) {
            return false;
        }

        return true;
    }

    @Dispatch()
    private loadInspections(inspections: Array<InspectionInterface>): LoadInspections {
        return new LoadInspections(inspections);
    }

    @Dispatch()
    public saveInspection(inspection: InspectionInterface): SaveInspection {
        return new SaveInspection(inspection);
    }

    @Dispatch()
    private pickInspection(inspectionId: string): PickInspection {
        return new PickInspection(inspectionId);
    }

    @Dispatch()
    private gotoInspection(url: string): Navigate {
        return new Navigate([url]);
    }
}
