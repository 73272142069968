import {
    Component,
    ChangeDetectionStrategy,
    OnInit,
    OnDestroy,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { TimeSheetService } from './services/time-sheet.service';

@Component({
    selector: 'actassa-jobs-placements',
    templateUrl: 'jobs-placements.component.html',
    styleUrls: ['jobs-placements.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JobsPlacementsComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    constructor(
        private readonly timeSheetService: TimeSheetService,
    ) { }

    public ngOnInit(): void {
        const subscription = this.timeSheetService.init$().subscribe();

        this.subscriptions.add(subscription);
    }

    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }
}
