import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { DetailListHeaderComponent } from './detail-list-header/detail-list-header.component';
import { DetailListItemComponent } from './detail-list-item/detail-list-item.component';
import { DetailListComponent } from './detail-list/detail-list.component';

@NgModule({
    imports: [
        CommonModule,
        IonicModule,
    ],
    declarations: [
        DetailListComponent,
        DetailListHeaderComponent,
        DetailListItemComponent,
    ],
    exports: [
        DetailListComponent,
        DetailListHeaderComponent,
        DetailListItemComponent,
    ],
})
export class DetailListModule { }
