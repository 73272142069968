import { DictionaryInterface } from '@actassa/api';
import { UserActionTypeEnum } from '../enums/user-action-type.enum';
import { InspectionQuestionDependenciesValueType } from '../types/inspection-question-dependencies.type';

export type UserAction = {
    actionType: UserActionTypeEnum.SHOW_APP_ALERT,
    actionData: ShowAppAlert,
    dependencies?: Array<DictionaryInterface<InspectionQuestionDependenciesValueType>>,
    dependencyTopLevelLogic?: LogicOperator,
} | {
    actionType: UserActionTypeEnum.SHOW_APP_MESSAGE,
    actionData: ShowAppMessage,
    dependencies?: Array<DictionaryInterface<InspectionQuestionDependenciesValueType>>,
    dependencyTopLevelLogic?: LogicOperator,
}

export enum LogicOperator {
    'AND' = 'AND',
    'OR' = 'OR',
}

export interface UserActionData {
    message?: string;
}

export interface ShowAppAlert extends UserActionData {
    header?: string;
    message: string;
}

export interface ShowAppMessage extends UserActionData {
    header?: string;
    message: string;
}
