import { Environment, ENVIRONMENT_TOKEN } from '@actassa/api';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { PlacementSearchDto } from './placement-search.dto';

interface ResponseDTO {
    status: string,
    message: string,
    data: Array<PlacementSearchDto>,
};

@Injectable()
export class PlacementSearchService {
    constructor(
        @Inject(ENVIRONMENT_TOKEN) private readonly environment: Environment,
        private readonly http: HttpClient,
    ) { }

    public search$(applicantId: number, clientId: number): Observable<Array<PlacementSearchDto>> {
        const url = `${this.environment.apiURL}/placement/search?applicantId=${applicantId}&clientId=${clientId}`;

        return this.http.get<ResponseDTO>(url)
            .pipe(
                map(({ data }) => data),
                catchError(() => of([])),
            );
    }
}
