import { MainMenuItemInterface } from '@actassa/api';
import { MenuTitlesDictionary } from './dictionaries/menu-titles.dictionary';
import { RoutesDictionary } from './dictionaries/routes.dictionary';
import { SectionsEnum } from './enums/sections.enum';

export const JobsPlacementsPages = (config: Partial<Record<SectionsEnum, string>>): Array<MainMenuItemInterface> => [
    {
        key: SectionsEnum.ASSIGNMENTS,
        title: config?.[SectionsEnum.ASSIGNMENTS] || MenuTitlesDictionary.ASSIGNMENTS,
        url: `jobs-placements/${RoutesDictionary.ASSIGNMENTS}`,
        icon: '/assets/custom-icons/my-assignments.svg',
        isActive: true,
    },
    {
        key: SectionsEnum.OPPORTUNITIES,
        title: config?.[SectionsEnum.OPPORTUNITIES] || MenuTitlesDictionary.OPPORTUNITIES,
        url: `jobs-placements/${RoutesDictionary.OPPORTUNITIES}`,
        icon: '/assets/custom-icons/job-opportunities.svg',
        isActive: true,
    },
    {
        key: SectionsEnum.APPLICATIONS,
        title: config?.[SectionsEnum.APPLICATIONS] || MenuTitlesDictionary.APPLICATIONS,
        url: `jobs-placements/${RoutesDictionary.APPLICATIONS}`,
        icon: '/assets/custom-icons/applications-in-progress.svg',
        isActive: true,
    },
    {
        key: SectionsEnum.HISTORY,
        title: config?.[SectionsEnum.HISTORY] || MenuTitlesDictionary.HISTORY,
        icon: '/assets/custom-icons/history.svg',
        isActive: true,
        submenu: [
            {
                key: SectionsEnum.FINISHED_ASSIGNMENTS,
                title: config?.[SectionsEnum.FINISHED_ASSIGNMENTS] || MenuTitlesDictionary.FINISHED_ASSIGNMENTS,
                url: `jobs-placements/${RoutesDictionary.FINISHED_ASSIGNMENTS}`,
                isActive: true,
            },
            {
                key: SectionsEnum.UNANSWERED_OPPORTUNITIES,
                title: config?.[SectionsEnum.UNANSWERED_OPPORTUNITIES] || MenuTitlesDictionary.UNANSWERED_OPPORTUNITIES,
                url: `jobs-placements/${RoutesDictionary.UNANSWERED_OPPORTUNITIES}`,
                isActive: true,
            },
            {
                key: SectionsEnum.DECLINED_OPPORTUNITIES,
                title: config?.[SectionsEnum.DECLINED_OPPORTUNITIES] || MenuTitlesDictionary.DECLINED_OPPORTUNITIES,
                url: `jobs-placements/${RoutesDictionary.DECLINED_OPPORTUNITIES}`,
                isActive: true,
            },
            {
                key: SectionsEnum.SUCCESSFUL_APPLICATIONS,
                title: config?.[SectionsEnum.SUCCESSFUL_APPLICATIONS] || MenuTitlesDictionary.SUCCESSFUL_APPLICATIONS,
                url: `jobs-placements/${RoutesDictionary.SUCCESSFUL_APPLICATIONS}`,
                isActive: true,
            },
            {
                key: SectionsEnum.UNSUCCESSFUL_APPLICATIONS,
                title: config?.[SectionsEnum.UNSUCCESSFUL_APPLICATIONS] || MenuTitlesDictionary.UNSUCCESSFUL_APPLICATIONS,
                url: `jobs-placements/${RoutesDictionary.UNSUCCESSFUL_APPLICATIONS}`,
                isActive: true,
            },
        ],
    },
    // {
    //     title: MenuTitlesDictionary.TIMESHEET,
    //     icon: 'mail',
    //     isActive: true,
    //     submenu: [
    //         {
    //             title: MenuTitlesDictionary.TIMESHEET_RECORD_TIME,
    //             url: RoutesDictionary.TIMESHEET_RECORD_TIME,
    //             icon: 'mail',
    //             isActive: true,
    //         },
    //         {
    //             title: MenuTitlesDictionary.TIMESHEET_VIEW_TIMESHEET,
    //             url: RoutesDictionary.TIMESHEET_VIEW_TIMESHEET,
    //             icon: 'mail',
    //             isActive: true,
    //         },
    //     ]
    // },
    {
        key: SectionsEnum.GEOFENCE,
        title: config?.[SectionsEnum.GEOFENCE] || MenuTitlesDictionary.GEOFENCE,
        url: `jobs-placements/${RoutesDictionary.GEOFENCE}`,
        icon: 'mail',
        isActive: true,
        isDebugOnly: true,
    },
];
