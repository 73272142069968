import { LOADER_DEFAULT_TEXT, LOADER_TIMEOUT } from 'constants/loader.constants';
import { Injectable } from '@angular/core';
import { LoadingController } from '@ionic/angular';
import { Actions, ofActionDispatched } from '@ngxs/store';
import { merge, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { LoadingEnd } from 'state/root-state/actions/loading-end';
import { LoadingStart } from 'state/root-state/actions/loading-start';

@Injectable({
    providedIn: 'root',
})
export class LoadingService {
    constructor(
        private readonly actions$: Actions,
        private readonly loadingController: LoadingController,
    ) { }

    public init(): Observable<unknown> {
        return merge(
            this.actions$
                .pipe(
                    ofActionDispatched(LoadingStart),
                    tap(() => this.showLoader()),
                ),
            this.actions$
                .pipe(
                    ofActionDispatched(LoadingEnd),
                    tap(() => this.hideLoader()),
                ),
        );
    }

    private async showLoader(text = LOADER_DEFAULT_TEXT): Promise<void> {
        const loading = await this.loadingController.create({
            message: text,
            duration: LOADER_TIMEOUT,
        });

        await loading.present();
    }

    private async hideLoader(): Promise<void> {
        try {
            await this.loadingController.dismiss();
        } catch (e) { }
    }
}
