import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'prettyJson',
})
export class PrettyJsonPipe implements PipeTransform {
    public transform<T>(value: T, ...args: Array<T>): string {
        return JSON.stringify(value, null, 2)
            .replace(/ /g, '&nbsp;')
            .replace(/\n/g, '<br/>');
    }
}
