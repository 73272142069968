import { isEmpty, isArray, isBoolean, isEqual, isNumber, isObject, isRegExp, isString, without } from 'lodash-es';
import { DependencyKeys } from '../enums/dependency-keys';
import { DependencyValueType } from '../types/inspection-question-dependencies.type';

type ComparatorFunction = (template: DependencyValueType, value: unknown) => boolean;

const comparatorStrategy: Record<DependencyKeys, ComparatorFunction> = {
    [DependencyKeys.EQ]: equal,
    [DependencyKeys.EXISTS]: exists,
    [DependencyKeys.GT]: greatThen,
    [DependencyKeys.GTE]: greatThenOrEqual,
    [DependencyKeys.IN]: inFn,
    [DependencyKeys.INCLUDES]: includes,
    [DependencyKeys.LT]: lessThen,
    [DependencyKeys.LTE]: lessThenOrEqual,
    [DependencyKeys.NE]: nonEqual,
    [DependencyKeys.REGEX]: regex,
};

export function checkDependency(template: DependencyValueType | Array<DependencyValueType>, value: unknown): boolean {
    if (isArray(template)) {
        return comparatorOrHelper(template, value);
    }

    if (isObject(template)) {
        return comparatorAndHelper(template, value);
    }

    return equal(template, value);
}

function comparatorAndHelper(template: DependencyValueType, value: unknown): boolean {
    if (isEmpty(template)) {
        return false;
    }

    return Object.keys(template)
        .reduce((accumulator: boolean, key: string) => {
            const helper = comparatorStrategy[key];

            if (!helper) {
                return false;
            }

            return accumulator && helper(template[key], value);
        }, true);
}

function comparatorOrHelper(template: Array<any>, value: unknown): boolean {
    if (isEmpty(template)) {
        return false;
    }

    return template.reduce((accumulator: boolean, item: DependencyValueType) => {
        if (isString(template) || isNumber(template)) {
            return equal(template, value) || accumulator;
        }

        if (isBoolean(template)) {
            return exists(template, value) || accumulator;
        }

        if (isRegExp(template)) {
            return regex(template, value) || accumulator;
        }

        if (isObject(template)) {
            return comparatorAndHelper(item, value) || accumulator;
        }

        return equal(template, value) || accumulator;
    }, false);
}

function equal(template: DependencyValueType, value: unknown): boolean {
    if (isString(template) && isString(value)) {
        return template?.toLocaleLowerCase() === value?.toLocaleLowerCase();
    }

    if (isNumber(template)) {
        return template === Number(value);
    }

    if (isBoolean(template)) {
        return exists(template, value);
    }

    if (isRegExp(template)) {
        return regex(template, value);
    }

    return isEqual(template, value);
}

function nonEqual(template: DependencyValueType, value: unknown): boolean {
    if (isString(template) && isString(value)) {
        return template?.toLocaleLowerCase() !== value?.toLocaleLowerCase();
    }

    if (isNumber(template)) {
        return template !== Number(value);
    }

    return !isEqual(template, value);
}

function greatThen(template: DependencyValueType, value: unknown): boolean {
    if (isString(template)) {
        return template < value;
    }

    if (isNumber(template)) {
        return template < Number(value);
    }

    return false;
}

function greatThenOrEqual(template: DependencyValueType, value: unknown): boolean {
    if (isString(template)) {
        return template <= value;
    }

    if (isNumber(template)) {
        return template <= Number(value);
    }

    return false;
}

function lessThen(template: DependencyValueType, value: unknown): boolean {
    if (isString(template)) {
        return template > value;
    }

    if (isNumber(template)) {
        return template > Number(value);
    }

    return false;
}

function lessThenOrEqual(template: DependencyValueType, value: unknown): boolean {
    if (isString(template)) {
        return template >= value;
    }

    if (isNumber(template)) {
        return template >= Number(value);
    }

    return false;
}

function exists(template: DependencyValueType, value: unknown): boolean {
    return Boolean(value) === template;
}

function regex(template: DependencyValueType, value: unknown): boolean {
    if (!isString(value)) {
        return false;
    }

    if (isRegExp(template)) {
        return template.test(value);
    }

    return false;
}

function inFn(template: DependencyValueType, value: unknown): boolean {
    if (isArray(value) && isArray(template)) {
        const resultArray = without(value, ...template);

        return !resultArray.length;
    }

    if (isString(value) && isString(template)) {
        return template.includes(value);
    }

    return false;
}

function includes(template: DependencyValueType, value: unknown): boolean {
    if (isArray(value) && isArray(template)) {
        const resultArray = without(template, ...value);

        return !resultArray.length;
    }

    if (isString(value) && isString(template)) {
        return value.includes(template);
    }

    return false;
}
