<ng-container *ngIf="isShowMenuItem; then shownMenu"></ng-container>

<ng-template #shownMenu>
    <ng-container *ngIf="hasSubmenu; then withSubmenu; else noSubmenu"></ng-container>
</ng-template>

<ng-template #withSubmenu>
    <ion-item (click)="toggleMenuItem(menuItem)" [detail]="true" [detailIcon]="detailIcon" lines="none">
        <ng-container *ngTemplateOutlet="menuItemContent"></ng-container>
    </ion-item>

    <ion-list *ngIf="menuItem.isOpen" class="sub-menu">
        <actassa-main-menu-item *ngFor="let submenuItem of menuItem.submenu; trackBy: trackByMenuItem"
            [menuItem]="submenuItem" [isDebugMode]="isDebugMode"></actassa-main-menu-item>
    </ion-list>
</ng-template>

<ng-template #noSubmenu>
    <ion-menu-toggle [autoHide]="false">
        <ng-container *ngTemplateOutlet="isExternalLink ? external : internal; context: { item: menuItem }">
        </ng-container>
    </ion-menu-toggle>
</ng-template>

<ng-template #internal>
    <ion-item [detail]="false" [routerLink]="menuItem.url" [routerLinkActive]="'selected'" routerDirection="root"
        lines="none">
        <ng-container *ngTemplateOutlet="menuItemContent"></ng-container>
    </ion-item>
</ng-template>

<ng-template #external>
    <ion-item [detail]="false" (click)="openExternalLink(menuItem.url)" lines="none">
        <ng-container *ngTemplateOutlet="menuItemContent"></ng-container>
    </ion-item>
</ng-template>

<ng-template #menuItemContent>
    <ng-container *ngIf="menuItem.icon">
        <ion-icon *ngIf="isCustomIcon" [src]="menuItem.icon" slot="start"></ion-icon>
        <ion-icon *ngIf="!isCustomIcon" [name]="menuItem.icon" slot="start"></ion-icon>
    </ng-container>
    <ion-label [ngClass]="labelClass">
        <div class="title">{{ menuItem.title }}</div>
        <div *ngIf="menuItem.subTitle" class="sub-title">{{ menuItem.subTitle }}</div>
        <div *ngIf="menuItem.subTitle$ | async as subTitle" class="sub-title">{{ subTitle }}</div>
    </ion-label>
</ng-template>