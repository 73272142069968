import { UserInterface } from '@actassa/api';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { InspectionsService } from './inspections.service';
import { TemplatesService } from './templates.service';

@Injectable()
export class CreateInspectionService {
    constructor(
        private readonly templatesService: TemplatesService,
        private readonly inspectionsService: InspectionsService,
    ) { }

    public process$(user: UserInterface, templateId: string): Observable<unknown> {
        return this.templatesService.load$()
            .pipe(
                switchMap((response: any) => {
                    if (!response?.data) {
                        throw new Error('Config error. Please contact support.');
                    }

                    const { data: templates } = response;
                    const dangerAlertTemplate = templates?.find(template => template.id === templateId);

                    if (!dangerAlertTemplate) {
                        throw new Error('Config error. Please contact support.');
                    }

                    const inspection = this.inspectionsService.mapTemplateInspection(dangerAlertTemplate, user);

                    return this.inspectionsService.batchInspectionCreate$(inspection, inspection.autoFinish);
                }),
            );
    }
}
