import { AppPathsDictionary } from '@actassa/api';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RoutesDictionary } from 'dictionaries/routes.dictionary';
import { AppAccessGuard } from 'guards/app-access.guard';
import { AuthGuard } from 'guards/auth.guard';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full',
    },
    {
        path: RoutesDictionary.LOGIN,
        loadChildren: () => import('./pages/login/login.module').then(m => m.LoginPageModule),
    },
    {
        path: RoutesDictionary.LOGOUT,
        loadChildren: () => import('./pages/logout/logout.module').then(m => m.LogoutPageModule),
    },
    {
        path: RoutesDictionary.SELECT_APP,
        canActivate: [AuthGuard],
        loadChildren: () => import('./pages/select-app/select-app.module').then(m => m.SelectAppPageModule),
    },
    {
        path: RoutesDictionary.FORGOT_PASSWORD,
        loadChildren: () => import('./pages/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule),
    },
    {
        path: 'shell',
        canActivate: [AuthGuard],
        loadChildren: () => import('./apps/shell/shell.module').then(m => m.ShellModule),
    },
    {
        path: AppPathsDictionary.CANDIDATE_TRN,
        canActivate: [AuthGuard, AppAccessGuard],
        loadChildren: () => import('./apps/candidate-trn/candidate-trn.module').then(m => m.CandidateTrnModule),
    },
    {
        path: AppPathsDictionary.CANDIDATE_ELE,
        canActivate: [AuthGuard, AppAccessGuard],
        loadChildren: () => import('./apps/candidate-ele/candidate-ele.module').then(m => m.CandidateEleModule),
    },
    {
        path: AppPathsDictionary.CONSULTANT_TRN,
        canActivate: [AuthGuard, AppAccessGuard],
        loadChildren: () => import('./apps/consultant-trn/consultant-trn.module').then(m => m.ConsultantTRNModule),
    },
    {
        path: AppPathsDictionary.CONSULTANT_ELE,
        canActivate: [AuthGuard, AppAccessGuard],
        loadChildren: () => import('./apps/consultant-ele/consultant-ele.module').then(m => m.ConsultantEleModule),
    },
    {
        path: AppPathsDictionary.CLIENT_TRN,
        canActivate: [AuthGuard, AppAccessGuard],
        loadChildren: () => import('./apps/client-trn/client-trn.module').then(m => m.ClientTrnModule),
    },
    {
        path: AppPathsDictionary.CLIENT_ELE,
        canActivate: [AuthGuard, AppAccessGuard],
        loadChildren: () => import('./apps/client-ele/client-ele.module').then(m => m.ClientEleModule),
    },
    {
        path: AppPathsDictionary.CANDIDATE_ONESTAFF,
        canActivate: [AuthGuard, AppAccessGuard],
        loadChildren: () => import('./apps/candidate-onestaff/candidate-onestaff.module').then(m => m.CandidateOnestaffModule),
    },
    {
        path: AppPathsDictionary.CONSULTANT_ONESTAFF,
        canActivate: [AuthGuard, AppAccessGuard],
        loadChildren: () => import('./apps/consultant-onestaff/consultant-onestaff.module').then(m => m.ConsultantOnestaffModule),
    },
    {
        path: AppPathsDictionary.CANDIDATE_ENTERPRISE,
        canActivate: [AuthGuard, AppAccessGuard],
        loadChildren: () => import('./apps/candidate-enterprise/candidate-enterprise.module').then(m => m.CandidateEnterpriseModule),
    },
    {
        path: AppPathsDictionary.CONSULTANT_ENTERPRISE,
        canActivate: [AuthGuard, AppAccessGuard],
        loadChildren: () => import('./apps/consultant-enterprise/consultant-enterprise.module').then(m => m.ConsultantEnterpriseModule),
    },
    {
        path: AppPathsDictionary.CANDIDATE_SYMMETRY,
        canActivate: [AuthGuard, AppAccessGuard],
        loadChildren: () => import('./apps/candidate-symmetry/candidate.module').then(m => m.CandidateModule),
    },
    {
        path: AppPathsDictionary.CONSULTANT_SYMMETRY,
        canActivate: [AuthGuard, AppAccessGuard],
        loadChildren: () => import('./apps/consultant-symmetry/consultant.module').then(m => m.ConsultantModule),
    },
    {
        path: AppPathsDictionary.CANDIDATE_ALL,
        canActivate: [AuthGuard, AppAccessGuard],
        loadChildren: () => import('./apps/candidate-all/candidate.module').then(m => m.CandidateModule),
    },
    {
        path: AppPathsDictionary.CONSULTANT_ALL,
        canActivate: [AuthGuard, AppAccessGuard],
        loadChildren: () => import('./apps/consultant-all/consultant.module').then(m => m.ConsultantModule),
    },
    {
        path: AppPathsDictionary.CANDIDATE_NSWBC,
        canActivate: [AuthGuard, AppAccessGuard],
        loadChildren: () => import('./apps/candidate-nswbc/candidate.module').then(m => m.CandidateModule),
    },
    {
        path: AppPathsDictionary.CONSULTANT_NSWBC,
        canActivate: [AuthGuard, AppAccessGuard],
        loadChildren: () => import('./apps/consultant-nswbc/consultant.module').then(m => m.ConsultantModule),
    },
];

@NgModule({
    imports: [
        RouterModule.forRoot(routes),
    ],
    exports: [RouterModule],
})
export class AppRoutingModule { }
