import { ApplicationsEnum } from '../enums/applications.enum';

export const AppTitlesDictionary: Record<ApplicationsEnum, string> = {
    [ApplicationsEnum.CANDIDATE]: 'CANDIDATE',
    [ApplicationsEnum.CANDIDATE_ALL]: 'CANDIDATE',
    [ApplicationsEnum.CANDIDATE_ENTERPRISE]: 'CANDIDATE',
    [ApplicationsEnum.CANDIDATE_NSWBC]: 'CANDIDATE',
    [ApplicationsEnum.CANDIDATE_ONESTAFF]: 'CANDIDATE',
    [ApplicationsEnum.CANDIDATE_SYMMETRY]: 'CANDIDATE',
    [ApplicationsEnum.CANDIDATE_TRN]: 'CANDIDATE',
    [ApplicationsEnum.CANDIDATE_ELE]: 'CANDIDATE',
    [ApplicationsEnum.CLIENT]: 'CLIENT',
    [ApplicationsEnum.CLIENT_TRN]: 'CLIENT',
    [ApplicationsEnum.CLIENT_ELE]: 'CLIENT',
    [ApplicationsEnum.CONSULTANT]: 'CONSULTANT',
    [ApplicationsEnum.CONSULTANT_ALL]: 'CONSULTANT',
    [ApplicationsEnum.CONSULTANT_ENTERPRISE]: 'CONSULTANT',
    [ApplicationsEnum.CONSULTANT_NSWBC]: 'CONSULTANT',
    [ApplicationsEnum.CONSULTANT_ONESTAFF]: 'CONSULTANT',
    [ApplicationsEnum.CONSULTANT_SYMMETRY]: 'CONSULTANT',
    [ApplicationsEnum.CONSULTANT_TRN]: 'CONSULTANT',
    [ApplicationsEnum.CONSULTANT_ELE]: 'CONSULTANT',
};
