import { PlacementOvertime } from '../../dto/placement.dto.interface';
import { PlacementInterface } from '../../interfaces/placement.interface';

export function buildDefaultPlacementOvertime(placement: PlacementInterface): PlacementOvertime {
    return {
        effectiveDate: null,
        expiryDate: null,
        note: '',
        overtimeChargeRate: placement.chargeRate,
        overtimePayRate: placement.payRate,
        overtimeValueDescription: 'Standard basic rate',
        overtimeValueId: null,
        overtimeValueName: 'Standard',
        overtimeValueSystemCode: null,
        placementOvertimeId: null,
        rateUnitListValueIdOverride: 0,
        rateUnitListValueIdOverrideDescription: null,
        rateUnitListValueIdOverrideName: null,
        rateUnitListValueIdOverrideSystemCode: null,
        disabled: false,
    };
}
