import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, Input } from '@angular/core';

@Component({
    selector: 'actassa-main-menu',
    templateUrl: './main-menu.component.html',
    styleUrls: ['./main-menu.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainMenuComponent implements AfterViewInit {
    @Input() public logoUrl: string;
    @Input() public footerUrl?: string;
    @Input() public footerColor?: string;

    constructor(private elementRef: ElementRef) { }

    public ngAfterViewInit(): void {
        if (this.footerUrl) {
            this.elementRef.nativeElement.style.setProperty(
                '--actassa-main-menu-background',
                `no-repeat bottom left url('${this.footerUrl}')`,
            );
        }
    }

}
