import { Environment, ENVIRONMENT_TOKEN, StoreWrapperInterface, STORE_WRAPPER_TOKEN } from '@actassa/api';
import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Actions, ofActionDispatched } from '@ngxs/store';
import { merge, Observable, of } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { LoadTemplates } from '../+state/actions/load-templates';
import { LoadTemplatesSuccess } from '../+state/actions/load-templates-success';
import { TemplateInterface } from '../interfaces/template.interface';

@Injectable()
export class TemplatesService {
    constructor(
        @Inject(STORE_WRAPPER_TOKEN) private storeWrapper: StoreWrapperInterface,
        @Inject(ENVIRONMENT_TOKEN) private readonly environment: Environment,
        private readonly actions$: Actions,
        private readonly http: HttpClient,
    ) { }

    public init$(): Observable<unknown> {
        return merge(
            this.actions$
                .pipe(
                    ofActionDispatched(LoadTemplates),
                    switchMap(() => this.load$()),
                    tap((response) => this.responseHandler(response)),
                    catchError((error) => {
                        this.storeWrapper.showToast(error.message);

                        return of();
                    }),
                ),
        );
    }

    public load$(): Observable<unknown> {
        return this.http.get(`${this.environment.apiURL}/inspections/templates`);
    }

    private responseHandler(response: any): void {
        if (!response) {
            this.storeWrapper.showToast('Server error.');

            return;
        }

        const { message, status, data } = response;

        this.storeWrapper.showToast(message);

        if (status === 'ok') {
            if (data) {
                this.loadTemplatesSuccess(data);
            }

            return;
        }
    }

    @Dispatch()
    private loadTemplatesSuccess(templates: Array<TemplateInterface>): LoadTemplatesSuccess {
        return new LoadTemplatesSuccess(templates);
    }
}
