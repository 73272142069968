import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SafePipe } from './safe.pipe';

@NgModule({
    declarations: [SafePipe],
    exports: [SafePipe],
    imports: [CommonModule],
})
export class SafeModule { }
