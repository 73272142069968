<ion-header translucent>
    <ion-toolbar>
        <ion-title>Select template</ion-title>
        <ion-buttons slot="end">
            <ion-button (click)="cancel()">Close</ion-button>
        </ion-buttons>
    </ion-toolbar>
</ion-header>
<ion-content>
    <ion-list>
        <ion-item *ngFor="let template of templates$ | async" (click)="select(template)">
            <ion-label>{{ template.title }}</ion-label>
        </ion-item>
    </ion-list>
</ion-content>