import {
    JobInterface,
    JobsSearchRequestInterface,
} from '@actassa/api';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { removeEmptyProps } from '../../helpers/removeEmptyProps.helper';
import { BaseApiService } from './base.api.service';

export interface JobSaveDtoInterface {
    chargeRate: number;
    clientContactId: number;
    clientId: number;
    employmentTypeId: number;
    jobId?: number;
    jobTitle: string;
    locationId: number;
    noOfPlaces: number;
    notes: string;
    payRate: number;
    positionId: number;
    primaryConsultantId: number;
    purchaseOrderNo: string;
    scheduleUseDiary: string;
    sectorId: number;
    statusId: number;
    userId: number;
    workAddress?: string;
}

@Injectable({
    providedIn: 'root',
})
export class JobsApiService {

    constructor(
        private readonly baseApiService: BaseApiService,
    ) { }

    public getMy$(): Observable<Array<JobInterface>> {
        return this.baseApiService.get$<Array<JobInterface>>(
            `job-api/job-search`,
            {
                defaultValue: [],
            },
        );
    }

    public search$(
        filter?: JobsSearchRequestInterface,
    ): Observable<Array<JobInterface>> {
        return this.baseApiService.get$<Array<JobInterface>>(
            `job-api/job-search`,
            {
                defaultValue: [],
                params: {
                    ...removeEmptyProps(filter),
                },
            },
        );
    }
}
