import { isEmpty, isPlainObject, transform } from 'lodash-es';

export const removeEmptyProps = (
    object,
    {
        cleanKeys = [] as Array<string | number | symbol>,
        cleanValues = [] as Array<any>,
        emptyArrays = true,
        emptyObjects = true,
        emptyStrings = true,
        naNValues = false,
        nullValues = true,
        undefinedValues = true,
    } = {},
): any => transform(object, (result, value, key) => {
    // Exclude specific keys.
    if (cleanKeys.includes(key)) {
        return;
    }

    // Recurse into arrays and objects.
    if (Array.isArray(value) || isPlainObject(value)) {
        value = removeEmptyProps(value, {
            naNValues,
            cleanKeys,
            cleanValues,
            emptyArrays,
            emptyObjects,
            emptyStrings,
            nullValues,
            undefinedValues,
        });
    }

    // Exclude specific values.
    if (cleanValues.includes(value)) {
        return;
    }

    // Exclude empty objects.
    if (emptyObjects && isPlainObject(value) && isEmpty(value)) {
        return;
    }

    // Exclude empty arrays.
    if (emptyArrays && Array.isArray(value) && !value.length) {
        return;
    }

    // Exclude empty strings.
    if (emptyStrings && value === '') {
        return;
    }

    // Exclude NaN values.
    if (naNValues && Number.isNaN(value)) {
        return;
    }

    // Exclude null values.
    if (nullValues && value === null) {
        return;
    }

    // Exclude undefined values.
    if (undefinedValues && value === undefined) {
        return;
    }

    // Append when recursing arrays.
    if (Array.isArray(result)) {
        return result.push(value);
    }

    result[key] = value;
});
