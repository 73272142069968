import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { Actions, ofAction } from '@ngxs/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ShowToast } from 'state/root-state/actions/show-toast';

@Injectable({
    providedIn: 'root',
})
export class ToastService {
    constructor(
        private readonly actions$: Actions,
        private readonly toastController: ToastController,
    ) { }

    public init(): Observable<unknown> {
        return this.actions$
            .pipe(
                ofAction(ShowToast),
                tap(({ payload }: ShowToast) => this.presentToast(payload)),
            );
    }

    private async presentToast(message: string): Promise<void> {
        if (!message) {
            return Promise.resolve();
        }

        const toast = await this.toastController.create({
            message,
            duration: 5000,
        });

        toast.present();
    }
}
