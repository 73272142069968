import { StoreWrapperInterface, STORE_WRAPPER_TOKEN } from '@actassa/api';
import { Inject, Injectable } from '@angular/core';
import { State, Action, StateContext, Selector } from '@ngxs/store';
import { differenceBy } from 'lodash-es';
import { DataCollectionStateInterface } from '../interfaces/data-collection-state.interface';
import { InspectionInterface } from '../interfaces/inspection.interface';
import { TemplateInterface } from '../interfaces/template.interface';
import { LoadInspections } from './actions/load-inspections';
import { LoadTemplatesSuccess } from './actions/load-templates-success';
import { PickInspection } from './actions/pick-inspection';
import { ResetState } from './actions/reset-state';
import { SaveInspection } from './actions/save-inspection';
import { UpdateInspection } from './actions/update-inspection';

const DEFAULT_STATE_DATA: DataCollectionStateInterface = {
    inspections: [],
    inspection: null,
    templates: [],
};

@State<DataCollectionStateInterface>({
    name: 'datacollection',
    defaults: DEFAULT_STATE_DATA,
})
@Injectable()
export class DataCollectionState {
    constructor(
        @Inject(STORE_WRAPPER_TOKEN) private readonly mainStore: StoreWrapperInterface,
    ) { }

    @Selector()
    public static inspection$(state: DataCollectionStateInterface): InspectionInterface | null {
        return state.inspection;
    }

    @Selector()
    public static inspections$(state: DataCollectionStateInterface): Array<InspectionInterface> {
        return state.inspections;
    }

    @Selector()
    public static templates$(state: DataCollectionStateInterface): Array<TemplateInterface> {
        return state.templates;
    }

    @Action(PickInspection)
    public pickInspection(stateContext: StateContext<DataCollectionStateInterface>, { inspectionId }: PickInspection): void {
        const inspection = stateContext.getState().inspections.find(i => i.id === inspectionId);

        stateContext.patchState({ inspection });
    }

    @Action(ResetState)
    public resetState(stateContext: StateContext<DataCollectionStateInterface>): void {
        stateContext.setState(DEFAULT_STATE_DATA);
    }

    @Action(SaveInspection)
    public saveInspection(stateContext: StateContext<DataCollectionStateInterface>, { inspection }: SaveInspection): void {
        const { inspections } = stateContext.getState();

        stateContext.patchState({
            inspections: [
                ...inspections.filter((i: InspectionInterface) => i.id !== inspection.id),
                inspection,
            ],
        });
    }

    @Action(LoadInspections)
    public loadInspections(
        stateContext: StateContext<DataCollectionStateInterface>,
        { inspections }: LoadInspections,
    ): void {
        const { uuid } = this.mainStore.user;
        const localInspections = stateContext.getState().inspections;
        const foreignLocalInspections = localInspections.filter(inspection => inspection.uuid !== uuid);
        const userLocalInspections = localInspections.filter(inspection => inspection.uuid === uuid);
        const newUserLocalInspections = differenceBy(userLocalInspections, inspections, 'id');
        const existUserLocalInspections = differenceBy(userLocalInspections, newUserLocalInspections, 'id');
        const newUserRemoteInspections = differenceBy(inspections, userLocalInspections, 'id');
        const existUserRemoteInspections = differenceBy(inspections, newUserRemoteInspections, 'id');
        const inspectionsForUpdate = [];
        const existInspections = existUserLocalInspections
            .reduce((accum: Array<InspectionInterface>, localInspection: InspectionInterface) => {
                const remoteInspection = existUserRemoteInspections
                    .find((i: InspectionInterface) => i.id === localInspection.id);

                if (!remoteInspection) {
                    return accum;
                }

                if (remoteInspection.modifiedAt < localInspection.modifiedAt) {
                    inspectionsForUpdate.push(localInspection);

                    return [...accum, localInspection];
                }

                return [...accum, remoteInspection];
            }, []);

        stateContext.dispatch([
            ...newUserLocalInspections,
            ...inspectionsForUpdate,
        ].map((inspection: InspectionInterface) => new UpdateInspection(inspection)));
        stateContext.patchState({
            inspections: [
                ...existInspections,
                ...newUserLocalInspections,
                ...newUserRemoteInspections,
                ...foreignLocalInspections,
            ],
        });
    }

    @Action(LoadTemplatesSuccess)
    public loadTemplates(stateContext: StateContext<DataCollectionStateInterface>, { templates }: LoadTemplatesSuccess): void {
        const localTemplates = stateContext.getState().templates || [];
        const foreignLocalTemplates = localTemplates
            .filter(template => template.providerID !== this.mainStore.providerId
                && template.application !== this.mainStore.app);

        stateContext.patchState({
            templates: [
                ...foreignLocalTemplates,
                ...templates,
            ],
        });
    }
}
