import { JobStatusInterface } from '@actassa/api';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { JobStatusesApiService } from '../api/job-statuses.api.service';

@Injectable({
    providedIn: 'root'
})
export class JobStatusesRepoService {
    private _jobsStatusesObservable$: Observable<Array<JobStatusInterface>>;
    private _jobsStatuses$ = new BehaviorSubject<Array<JobStatusInterface>>([]);

    constructor(
        private readonly jobStatusesApiService: JobStatusesApiService,
    ) { }

    public get jobStatuses$(): Observable<Array<JobStatusInterface>> {
        if (!this._jobsStatusesObservable$) {
            this._jobsStatusesObservable$ = this._jobsStatuses$.asObservable();
        }

        return this._jobsStatusesObservable$;
    }

    public loadJobStatuses$(): Observable<Array<JobStatusInterface>> {
        return this.jobStatusesApiService
            .getAll$()
            .pipe(
                take(1),
                tap(jobsStatuses => this._jobsStatuses$.next(jobsStatuses)),
            );
    }

}
