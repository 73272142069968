import { PlatformEnum } from '@actassa/api';
import { Injectable } from '@angular/core';
import { DeviceInfo, Plugins } from '@capacitor/core';
import { Diagnostic } from '@ionic-native/diagnostic/ngx';
import { AlertController } from '@ionic/angular';
import { from, Observable, of } from 'rxjs';
import { delay, distinctUntilChanged, filter, switchMap, tap, map } from 'rxjs/operators';

const { Device } = Plugins;
const TIMEOUT = 20000;
const ALERT_MESSAGE = `This app requires notifications to be allowed to be really useful to you.
Please turn on notifications for this app in use your device settings.
If you need any assistance, please write to support@actassa.com.
This app won't work well if notifications are not enabled. Thank you!`;

@Injectable({
    providedIn: 'root',
})
export class PermissionsService {
    constructor(
        private readonly alertController: AlertController,
        private readonly diagnostic: Diagnostic,
    ) { }

    public init$(): Observable<unknown> {
        return of()
            .pipe(
                delay(TIMEOUT),
                switchMap(() => from(Device.getInfo())),
                map(({ platform }: DeviceInfo) => platform),
                filter(platform => platform !== PlatformEnum.WEB),
                distinctUntilChanged(),
                switchMap(deviceType => from(this.isPushNotificationEnabled())
                    .pipe(
                        filter(value => !value),
                        tap(() => this.showAlert(ALERT_MESSAGE, deviceType)),
                    )),
            );
    }

    private async isPushNotificationEnabled(): Promise<boolean> {
        return this.diagnostic.isRemoteNotificationsEnabled();
    }

    private async showAlert(message: string, deviceType: string): Promise<void> {
        const actionButtons = {
            [PlatformEnum.ANDROID]: {
                text: 'Go to settings',
                handler: () => this.diagnostic.switchToSettings(),
            },
            [PlatformEnum.IOS]: {
                text: 'Request',
                handler: () => this.diagnostic.requestRemoteNotificationsAuthorization(['alert', 'badge', 'sound']),
            },
        };
        const alert = await this.alertController.create({
            header: 'Alert',
            message,
            buttons: [
                actionButtons[deviceType],
                {
                    text: 'Ok',
                    role: 'cancel',
                    cssClass: 'secondary',
                },
            ],
        });

        await alert.present();
    }
}
