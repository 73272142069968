<ion-menu content-id="app-content" side="start" menu-id="mainMenu">
    <ion-content #content>
        <ion-list class="ion-no-padding">
            <ion-item *ngIf="logoUrl" lines="none" class="ion-no-padding ion-no-margin">
                <ion-img [src]="logoUrl" class="logo"></ion-img>
            </ion-item>
            <ng-content></ng-content>
        </ion-list>
        <!-- <div *ngIf="footerUrl" class="footer"> </div> -->
    </ion-content>
</ion-menu>
