import { ApplicationsEnum, FcmMessageDataInterface, MainMenuItemInterface, MobileApplicationConfig, StoreWrapperInterface, UserInterface } from '@actassa/api';
import { AppPathsDictionary } from '@actassa/api';
import { NetworkStatusService } from '@actassa/shared';
import { Injectable } from '@angular/core';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { SelectSnapshot } from '@ngxs-labs/select-snapshot';
import { Select } from '@ngxs/store';
import { isEqual } from 'lodash-es';
import { Observable } from 'rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { ClearNotificationData } from 'state/root-state/actions/clear-notification-data';
import { LoadingEnd } from 'state/root-state/actions/loading-end';
import { LoadingStart } from 'state/root-state/actions/loading-start';
import { ShowAlert } from 'state/root-state/actions/show-alert';
import { ShowToast } from 'state/root-state/actions/show-toast';
import { RootState } from 'state/root-state/root.state';
import { JoinConsultant } from '../modules/messaging/+state/actions/join-consultant';

@Injectable({
    providedIn: 'root',
})
export class StoreWrapperService implements StoreWrapperInterface {
    @Select(RootState.app$) public app$: Observable<ApplicationsEnum>;
    @Select(RootState.appSettings$) public appSettings$: Observable<Partial<MobileApplicationConfig> | null>;
    @Select(RootState.isAppStateActive$) public isAppStateActive$: Observable<boolean>;
    @Select(RootState.isAuthenticated$) public isAuthenticated$: Observable<boolean>;
    @Select(RootState.isDebugMode$) public isDebugMode$: Observable<boolean>;
    @Select(RootState.providerId$) public providerId$: Observable<string | null>;
    @Select(RootState.timezone$) public timezone$: Observable<string>;
    @Select(RootState.user$) public user$: Observable<UserInterface>;
    @SelectSnapshot(RootState.app$) public app: ApplicationsEnum;
    @SelectSnapshot(RootState.providerId$) public providerId: string | null;
    @SelectSnapshot(RootState.user$) public user: UserInterface;

    @Select(RootState.notificationData$) private _notificationData$: Observable<FcmMessageDataInterface>;

    public isNetworkConnected$: Observable<boolean>;

    constructor(
        private readonly networkStatusService: NetworkStatusService,
    ) {
        this.isNetworkConnected$ = this.networkStatusService.isNetworkConnected$;
    }

    public get baseUrl$(): Observable<string> {
        return this.app$.pipe(map((app) => AppPathsDictionary[app]));
    }

    public get notificationData$(): Observable<FcmMessageDataInterface> {
        return this._notificationData$.pipe(distinctUntilChanged(isEqual));
    }

    public getPageTitle$(menuKey: string): Observable<string | undefined> {
        return this.appSettings$
            .pipe(
                map(settings => {
                    if (!settings) {
                        return;
                    }

                    const menuItem = this.getMenuItem(Object.values(settings), menuKey);

                    if (!menuItem) {
                        return;
                    }

                    const { title, pageTitle } = menuItem;

                    return pageTitle || title;
                }),
            );
    }

    public getMenuItem$(menuKey: string): Observable<MainMenuItemInterface | undefined> {
        return this.appSettings$
            .pipe(
                map(settings => {
                    if (!settings) {
                        return;
                    }

                    return this.getMenuItem(Object.values(settings), menuKey);
                }),
            );
    }

    public getMenuItemProperty$<T>(menuKey: string, propertyKey: string): Observable<T | undefined> {
        return this.appSettings$
            .pipe(
                map(settings => {
                    if (!settings) {
                        return;
                    }

                    const menuItem = this.getMenuItem(Object.values(settings), menuKey);

                    if (!menuItem) {
                        return;
                    }

                    return menuItem[propertyKey];
                }),
            );
    }

    @Dispatch()
    public showAlert(message: string): ShowAlert {
        return new ShowAlert(message);
    }

    @Dispatch()
    public showToast(message: string): ShowToast {
        return new ShowToast(message);
    }

    @Dispatch()
    public loadingEnd(): LoadingEnd {
        return new LoadingEnd();
    }

    @Dispatch()
    public loadingStart(): LoadingStart {
        return new LoadingStart();
    }

    @Dispatch()
    public joinConsultant(id: string, role: string, message: string): JoinConsultant {
        return new JoinConsultant(id, role, message);
    }

    @Dispatch()
    public clearNotificationData(): ClearNotificationData {
        return new ClearNotificationData();
    }

    private getMenuItem(settings: Array<MainMenuItemInterface>, key: string): MainMenuItemInterface | undefined {
        return settings
            .reduce((accumulator, item) => {
                if (item.key === key) {
                    return item;
                }

                if (item.submenu) {
                    return item.submenu.find(subItem => subItem.key === key) || accumulator;
                }

                return accumulator;
            }, undefined);
    }
}
