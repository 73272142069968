import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'actassa-detail-list-header',
    templateUrl: './detail-list-header.component.html',
    styleUrls: ['./detail-list-header.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DetailListHeaderComponent {
    @Input() public value: string | undefined;
}
