import { Environment, ENVIRONMENT_TOKEN, PlatformEnum } from '@actassa/api';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Plugins } from '@capacitor/core';
import { AlertButton, AlertController } from '@ionic/angular';
import { Observable, throwError } from 'rxjs';
import { catchError, map, retryWhen, tap } from 'rxjs/operators';
import { genericRetryStrategy } from '../helpers/retry.strategy';

const { App } = Plugins;
const ALERT_TITLE = 'New App Version';
const platformDictionary = {
    android: 'Google Play Store',
    ios: 'Apple App Store',
};
const EXCEPTIONAL_IOS_BUTTONS = [
    {
        text: 'Ok',
        role: 'cancel',
    },
];

interface VersionCheckResponseDTO {
    data: string;
    message: string;
    status: string;
}

@Injectable({
    providedIn: 'root',
})
export class VersionService {
    constructor(
        @Inject(ENVIRONMENT_TOKEN) private readonly environment: Environment,
        private readonly alertController: AlertController,
        private readonly http: HttpClient,
    ) { }

    public checkAppVersion$(appId: string, version: string, platform: string): Observable<string> {
        const url = this.buildUrl();

        return this.http.post<VersionCheckResponseDTO>(url, { app: appId, version, platform })
            .pipe(
                retryWhen(genericRetryStrategy()),
                map(({ data }: VersionCheckResponseDTO) => data),
                tap((status: string) => {
                    if (platform === 'web') {
                        return undefined;
                    }

                    if (!status || status === 'actual') {
                        return undefined;
                    }

                    const storeTitle = platformDictionary[platform];

                    if (status === 'mustUpdate') {
                        const buttons = platform === PlatformEnum.IOS
                            ? EXCEPTIONAL_IOS_BUTTONS
                            : [
                                {
                                    text: 'Close App',
                                    handler: (): void => App.exitApp(),
                                },
                            ];

                        return this.mustUpdate(storeTitle, buttons);
                    }

                    if (status === 'canUpdate') {
                        const buttons = platform === PlatformEnum.IOS
                            ? EXCEPTIONAL_IOS_BUTTONS
                            : [
                                {
                                    text: 'Close App',
                                    handler: (): void => App.exitApp(),
                                },
                                {
                                    text: 'Remind Later',
                                    role: 'cancel',
                                },
                            ];

                        return this.canUpdate(storeTitle, buttons);
                    }

                    return undefined;
                }),
                catchError((error: HttpErrorResponse) => throwError(() => new Error(error.message))),
            );
    }

    private buildUrl(): string {
        return `${this.environment.apiURL}/version/check`;
    }

    private async canUpdate(storeTitle: string, buttons: Array<AlertButton>): Promise<void> {
        const message = `A new version of ${this.environment.appName} is now available, 
        with improvements for your best experience. 
        Please close the app and get the update from ${storeTitle}. 
        If this does not work, you may need to uninstall the current version and then install the new version.`;
        const alert = await this.alertController.create({
            header: ALERT_TITLE,
            message,
            backdropDismiss: false,
            buttons,
        });

        await alert.present();
    }

    private async mustUpdate(storeTitle: string, buttons: Array<AlertButton>): Promise<void> {
        const message = `A new version of ${this.environment.appName} is now available, 
        with essential improvements for your best experience.
        Please close the app and get the update from ${storeTitle}.
        If this does not work, you may need to uninstall the current version and then install the new version.`;
        const alert = await this.alertController.create({
            header: ALERT_TITLE,
            message,
            backdropDismiss: false,
            buttons,
        });

        await alert.present();
    }
}
