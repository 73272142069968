import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { NgxsModule } from '@ngxs/store';
import { DataCollectionState } from './+state/data-collection.state';
import { FormTemplateModalComponent } from './components/form-template-modal/form-template-modal.component';
import { DataCollectionRoutingModule } from './data-collection-routing.module';
import { DataCollectionComponent } from './data-collection.component';
import { CreateInspectionService } from './services/create-inspection.service';
import { InspectionsService } from './services/inspections.service';
import { PushHandlerService } from './services/push-handler.service';
import { TemplatesService } from './services/templates.service';

@NgModule({
    declarations: [
        DataCollectionComponent,
        FormTemplateModalComponent,
    ],
    imports: [
        CommonModule,
        IonicModule,
        DataCollectionRoutingModule,
        NgxsModule.forFeature([DataCollectionState]),
    ],
})
export class DataCollectionModule {
    public static forRoot(): ModuleWithProviders<DataCollectionModule> {
        return {
            ngModule: DataCollectionModule,
            providers: [
                CreateInspectionService,
                InspectionsService,
                PushHandlerService,
                TemplatesService,
            ],
        };
    }
}
