import * as moment from 'moment';
import { valueExtractorHelper } from './value-extractor.helper';

const enum ControlTypes {
    DATE = 'date',
    DATETIME = 'datetime',
    SIGNATURE = 'signature',
    TIME = 'time',
}

type controlSanitizerFunctionType = (value: string) => string;

const questionValueSanitizerMap: Record<ControlTypes, controlSanitizerFunctionType> = {
    [ControlTypes.DATE]: (value: string) => moment(value).format('DD/MM/YYYY'),
    [ControlTypes.DATETIME]: (value: string) => moment(value).format('DD/MM/YYYY hh:mm A'),
    [ControlTypes.SIGNATURE]: (value: string) => value.replace('data:image/png;base64,', 'image:base64:'),
    [ControlTypes.TIME]: (value: string) => moment(value).format('hh:mm A'),
};

export const questionValueSanitizerHelper = (type: string, value: string): string => {
    const helper = questionValueSanitizerMap[type];

    if (helper) {
        return helper(value);
    }

    return valueExtractorHelper(value);
};
