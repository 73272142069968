import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class AppAccessGuard implements CanActivate {
    constructor() { }

    public canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot,
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        // const apps = this.store.selectSnapshot(RootState.auths$);
        // const check = !!apps.find(({ role }) => AppPathsDictionary[role] === next.routeConfig.path);

        // if (check) {
        //     return true;
        // }

        // this.gotoLogout();

        // return false;

        return true;
    }

    // @Dispatch()
    // private gotoLogout(): Navigate {
    //     return new Navigate([RoutesDictionary.LOGOUT]);
    // }
}
