import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { Actions, ofActionDispatched } from '@ngxs/store';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { ShowAlert } from 'state/root-state/actions/show-alert';

@Injectable({
    providedIn: 'root',
})
export class AlertService {
    constructor(
        private readonly actions$: Actions,
        private readonly controller: AlertController,
    ) { }

    public init(): Observable<unknown> {
        return this.actions$
            .pipe(
                ofActionDispatched(ShowAlert),
                tap(({ payload }: ShowAlert) => this.present(payload)),
            );
    }

    private async present(message: string): Promise<void> {
        const alert = await this.controller.create({
            message,
            buttons: [
                {
                    text: 'OK',
                    role: 'cancel',
                },
            ],
        });

        await alert.present();
    }
}
