import { JobDTOInterface } from '../dto/job.dto.interface';
import { PlacementDTOInterface } from '../dto/placement.dto.interface';
import { ShiftDTOInterface } from '../dto/shift.dto.interface';
import { JobInterface } from '../interfaces/job.interface';
import { JobsPlacementsInterface } from '../interfaces/jobs-placements-state.interface';
import { PlacementInterface } from '../interfaces/placement.interface';
import { ShiftInterface } from '../interfaces/shift.interface';
import { jobFromDto, jobToDto, placementFromDto, placementToDto, shiftFromDto, shiftToDto } from './mapper.helper';

interface SerializedState {
    [key: string]: any,
    jobsplacements: JobsPlacementsSerializedState | undefined,
}

interface DeserializedState {
    [key: string]: any,
    jobsplacements: JobsPlacementsInterface | undefined,
}

declare type JobsPlacementsSerializedState =
    Omit<JobsPlacementsInterface, 'job' | 'shift' | 'placement' | 'jobs' | 'shifts' | 'placements'> &
    {
        job: JobDTOInterface | null,
        jobs: Array<JobDTOInterface>,
        shift: ShiftDTOInterface | null,
        shifts: Array<ShiftDTOInterface>,
        placement: PlacementDTOInterface | null,
        placements: Array<PlacementDTOInterface>,
    };

export function jobsPlacementsBeforeSerialize(deserializedState: DeserializedState, key: string): SerializedState {
    const jobsPlacementStore = deserializedState.jobsplacements;

    const state = {
        ...deserializedState,
        jobsplacements: jobsPlacementStore ? {
            ...jobsPlacementStore,
            job: jobsPlacementStore.job ? jobToDto(jobsPlacementStore.job) : null,
            jobs: jobsPlacementStore.jobs ? jobsPlacementStore.jobs.map(jobToDto) : [] as Array<JobDTOInterface>,
            shift: jobsPlacementStore.shift ? shiftToDto(jobsPlacementStore.shift) : null,
            shifts: jobsPlacementStore.shifts ? jobsPlacementStore.shifts.map(shiftToDto) : [] as Array<ShiftDTOInterface>,
            placement: jobsPlacementStore.placement ? placementToDto(jobsPlacementStore.placement) : null,
            placements: jobsPlacementStore.placements
                ? jobsPlacementStore.placements.map(placementToDto)
                : [] as Array<PlacementDTOInterface>,
        } : undefined,
    };

    return state;

}

export function jobsPlacementsAfterDeserialize(serializedState: SerializedState, key: string): DeserializedState {
    const jobsPlacementStore = serializedState.jobsplacements;

    let parsedData = {
        job: null as JobInterface | null,
        jobs: [] as Array<JobInterface>,
        shift: null as ShiftInterface | null,
        shifts: [] as Array<ShiftInterface>,
        placement: null as PlacementInterface | null,
        placements: [] as Array<PlacementInterface>,
    };

    try {
        parsedData = {
            job: jobsPlacementStore?.job ? jobFromDto(jobsPlacementStore.job) : null,
            jobs: jobsPlacementStore?.jobs ? jobsPlacementStore.jobs.map(jobFromDto) : [] as Array<JobInterface>,
            shift: jobsPlacementStore?.shift ? shiftFromDto(jobsPlacementStore.shift) : null,
            shifts: jobsPlacementStore?.shifts ? jobsPlacementStore.shifts.map(shiftFromDto) : [] as Array<ShiftInterface>,
            placement: jobsPlacementStore?.placement ? placementFromDto(jobsPlacementStore.placement) : null,
            placements: jobsPlacementStore?.placements
                ? jobsPlacementStore.placements.map(placementFromDto)
                : [] as Array<PlacementInterface>,
        };
    } catch (e) {
        console.error(e);

    }

    const state = {
        ...serializedState,
        jobsplacements: jobsPlacementStore ? {
            ...jobsPlacementStore,
            ...parsedData,
        } : undefined,
    };

    return state;

}
