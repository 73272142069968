<ion-row class="ion-margin-top">
    <ion-col *ngIf="title" [size]="titleSize" class="ion-no-padding">
        <ion-label color="primary">
            <h3 class="ion-no-margin">{{ title }}</h3>
        </ion-label>
    </ion-col>
    <ion-col [size]="title ? totalSize - titleSize : totalSize" class="ion-no-padding">
        <ion-label color="dark">
            <h3 *ngIf="value" class="ion-no-margin">{{ value }}</h3>
            <h3 *ngIf="htmlValue" [innerHtml]="htmlValue" class="ion-no-margin"></h3>
            <ng-container *ngIf="values">
                <h3 *ngFor="let value of values" class="ion-no-margin">
                    {{ value }}
                </h3>
            </ng-container>
            <ng-container *ngIf="htmlValues">
                <h3 *ngFor="let htmlValue of htmlValues" [innerHTML]="htmlValue" class="ion-no-margin"> </h3>
            </ng-container>
        </ion-label>
    </ion-col>
</ion-row>