import { MainMenuItemInterface } from '@actassa/api';
import { Injectable } from '@angular/core';
import { isFunction, isObject } from 'lodash-es';

declare type MainMenuItemTransformFunc = (item: MainMenuItemInterface) => Partial<MainMenuItemInterface>;

interface MainMenuItemTransformPair { key: string, fnc: MainMenuItemTransformFunc };

declare type MainMenuItemTransformInterface =
    Partial<Omit<MainMenuItemInterface, 'submenu'> & { submenu: Array<MainMenuItemTransformInterface> }>

declare type MainMenuItemRawTransform = MainMenuItemTransformInterface | MainMenuItemTransformPair;

declare type MainMenuItemTransform = MainMenuItemTransformInterface | MainMenuItemTransformFunc;

declare type MainMenuItemsTransformsMap = Record<string, Array<MainMenuItemTransform>>;

// declare type MainMenuItemsHideMap = Record<string, never>;

@Injectable({
    providedIn: 'root',
})
export class MainMenuItemsService {
    public transformMainMenuItems(
        items: Array<MainMenuItemInterface>,
        rawTransforms: Array<MainMenuItemRawTransform>,
        // hiddenKeys: Array<string>,
    ): Array<MainMenuItemInterface> {
        const transformsMap = this.buildTransformsMap(rawTransforms);
        // const hideMap = this.buildHideMap(hiddenKeys);
        const transformedItems = this._transformMainMenuItems(items, transformsMap);
        // const filteredTransformedItems = this.hideMainMenuItems(transformedItems, hideMap);

        return transformedItems;
    }

    private buildTransformsMap(
        rawTransforms: Array<MainMenuItemRawTransform>,
        mainMenuItemsTransformsMap: MainMenuItemsTransformsMap = {},
    ): MainMenuItemsTransformsMap {
        return rawTransforms.reduce((transformsMap, transform) => {
            if (transform.key) {
                transformsMap[transform.key] = transformsMap[transform.key] || [];

                const isItPair = !!transform['fnc'];

                if (isItPair) {
                    transformsMap[transform.key].push((transform as MainMenuItemTransformPair).fnc);
                } else {
                    transform = transform as MainMenuItemInterface;

                    if (transform.key) {
                        transformsMap[transform.key].push(transform);

                        if (transform.submenu) {
                            this.buildTransformsMap(transform.submenu, transformsMap);
                        }
                    }
                }
            }

            return transformsMap;
        }, mainMenuItemsTransformsMap);
    }

    private _transformMainMenuItems(
        items: Array<MainMenuItemInterface>,
        transformsMap: MainMenuItemsTransformsMap,
    ): Array<MainMenuItemInterface> {
        return items.map(item => this.transformMainMenuItem(item, transformsMap));
    }

    private transformMainMenuItem(
        item: MainMenuItemInterface,
        transformsMap: MainMenuItemsTransformsMap,
    ): MainMenuItemInterface {
        if (item.key && transformsMap[item.key]) {

            if (item.submenu) {
                item.submenu = this._transformMainMenuItems(item.submenu, transformsMap);
            }

            item = this.applyMainMenuItemsTransformation(item, transformsMap[item.key] as Array<MainMenuItemTransform>);

        }

        return item;
    }

    private applyMainMenuItemsTransformation(
        item: MainMenuItemInterface,
        transforms: Array<MainMenuItemTransform>,
    ): MainMenuItemInterface {
        transforms.forEach(transform => {
            const submenu = item.submenu;

            if (isFunction(transform)) {
                item = {
                    ...item,
                    ...(transform as MainMenuItemTransformFunc)(item),
                    submenu,
                };
                return;
            } else if (isObject(transform)) {
                item = {
                    ...item,
                    ...(transform as MainMenuItemInterface),
                    submenu,
                };
                return;
            }
        });

        return item;
    }

    // private buildHideMap(hiddenKeys: Array<string>): MainMenuItemsHideMap {
    //     return hiddenKeys.reduce((hideMap, key) => {
    //         hideMap[key] = false as never;
    //         return hideMap;
    //     }, {} as MainMenuItemsHideMap);
    // }

    // private hideMainMenuItems(items: Array<MainMenuItemInterface>, hideMap: MainMenuItemsHideMap): Array<MainMenuItemInterface> {
    //     return items
    //         .filter((item) => hideMap[item.key])
    //         .map(item => {
    //             if (item.submenu) {
    //                 item.submenu = this.hideMainMenuItems(item.submenu, hideMap);
    //             }

    //             return item;
    //         });
    // }
}