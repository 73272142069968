import { SectionsEnum } from '../enums/sections.enum';

export const MenuTitlesDictionary: Record<SectionsEnum, string> = {
    [SectionsEnum.APPLICATIONS]: 'Applications in Progress',
    [SectionsEnum.ASSIGNMENTS]: 'My Assignments',
    [SectionsEnum.DECLINED_OPPORTUNITIES]: 'Declined Opportunities',
    [SectionsEnum.FENCE_ALERT]: 'Fence alert',
    [SectionsEnum.FINISHED_ASSIGNMENTS]: 'Finished Assignments',
    [SectionsEnum.GEOFENCE]: 'Geofence',
    [SectionsEnum.HISTORY]: 'History',
    [SectionsEnum.JOB]: 'Job',
    [SectionsEnum.OPPORTUNITIES]: 'Job Opportunities',
    [SectionsEnum.PLACEMENT]: 'Placement',
    [SectionsEnum.RECORD_EXPENSE]: 'Expense Details',
    [SectionsEnum.RECORD_TIME_DAILY]: 'Record Time',
    [SectionsEnum.RECORD_TIME_ENTITY]: 'Record Time',
    [SectionsEnum.RECORD_TIME]: 'Record Time',
    [SectionsEnum.SHIFT_CONFIRMATIONS]: 'Shift confirmations',
    [SectionsEnum.SHIFT]: 'Shift',
    [SectionsEnum.SUCCESSFUL_APPLICATIONS]: 'Successful Applications',
    [SectionsEnum.TIMESHEET_RECORD_TIME_SELECTED]: 'Timesheet record selected',
    [SectionsEnum.TIMESHEET_VIEW_TIMESHEET]: 'View Timesheet',
    [SectionsEnum.TIMESHEET]: 'Timesheets',
    [SectionsEnum.UNANSWERED_APPLICATIONS]: 'Unanswered Applications',
    [SectionsEnum.UNANSWERED_OPPORTUNITIES]: 'Expired Opportunities',
    [SectionsEnum.UNSUCCESSFUL_APPLICATIONS]: 'Unsuccessful Applications',
};
