import { TemplateControl } from '../services/template-control';
import { questionValueSanitizerHelper } from './value-sanitizer.helper';

export function initSubstitutionStream(template: string, form: any, questions: Array<TemplateControl<any>>): string {
    if (!template) {
        return '';
    }

    const fullKeyRegex = /%values\.\w+%/g;
    const keyRegex = /^values\./;
    const delimiterRegex = /%/g;
    const matches: Array<string> = template.match(fullKeyRegex) || [];

    if (matches.length < 1) {
        return template;
    }

    const fields: Array<string> = matches.map(match => match.replace(delimiterRegex, '').replace(keyRegex, ''));

    return fields.reduce((accum, field, index) => {
        const regex = new RegExp(matches[index]);
        const value = form[field];
        const question = questions.find(q => q.key === field);

        if (!question) {
            return accum;
        }

        return accum.replace(regex, questionValueSanitizerHelper(question.controlType, value));

    }, template);
}
