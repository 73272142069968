import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
    selector: 'actassa-main-menu-user-info',
    templateUrl: './main-menu-user-info.component.html',
    styleUrls: ['./main-menu-user-info.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainMenuUserInfoComponent {
    @Input() public userInfo: { firstName: string, lastName: string, email?: string };
}
