import { JobEmploymentTypeInterface } from '@actassa/api';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { JobEmploymentTypesApiService } from '../api/job-employment-types.api.service';

@Injectable({
    providedIn: 'root'
})
export class JobEmploymentTypesRepoService {

    private _jobsEmploymentTypes$ = new BehaviorSubject<Array<JobEmploymentTypeInterface>>([]);

    constructor(
        private readonly jobEmploymentTypesApiService: JobEmploymentTypesApiService,
    ) { }

    public get jobEmploymentTypes$(): Observable<Array<JobEmploymentTypeInterface>> {
        return this._jobsEmploymentTypes$.asObservable();
    }

    public loadJobEmploymentTypes$(): Observable<Array<JobEmploymentTypeInterface>> {
        return this.jobEmploymentTypesApiService
            .getAll$()
            .pipe(
                take(1),
                tap(jobEmploymentTypes =>
                    this._jobsEmploymentTypes$.next(jobEmploymentTypes),
                ),
            );
    }
}
