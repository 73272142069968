import {
    Environment,
    ENVIRONMENT_TOKEN,
    ServerResponseInterface,
    StoreWrapperInterface,
    STORE_WRAPPER_TOKEN,
} from '@actassa/api';
import { HttpClient, HttpContext, HttpHeaders, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { omit } from 'lodash-es';
import { Observable, of } from 'rxjs';
import { catchError, map, take, tap } from 'rxjs/operators';

interface httpOptions<T = undefined> {
    defaultValue?: T;
    body?: any;
    headers?: HttpHeaders | {
        [header: string]: string | Array<string>;
    };
    context?: HttpContext;
    observe?: string;
    params?: HttpParams | {
        [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
    };
    reportProgress?: boolean;
    responseType?: string;
    withCredentials?: boolean;
}

@Injectable({
    providedIn: 'root',
})
export class BaseApiService {

    constructor(
        @Inject(ENVIRONMENT_TOKEN) private readonly environment: Environment,
        @Inject(STORE_WRAPPER_TOKEN) protected storeWrapper: StoreWrapperInterface,
        private readonly http: HttpClient,
    ) { }

    public get$<T = undefined>(url: string, options?: httpOptions<T>): Observable<T> {
        return this.request('get', url, options);
    }

    public post$<T = undefined>(url: string, options?: httpOptions<T>): Observable<T> {
        return this.request('post', url, options);
    }

    public delete$<T = undefined>(url: string, options?: httpOptions<T>): Observable<T> {
        return this.request('delete', url, options);
    }

    private request<T = undefined>(
        method: 'get' | 'post' | 'put' | 'delete',
        url: string,
        options?: httpOptions<T>,
    ): Observable<T> {
        return (
            method === 'post'
                ? this.http[method](`${this.environment.apiURL}/${url}`, options?.body || {}, omit(options, 'body') as any)
                : this.http[method](`${this.environment.apiURL}/${url}`, options as any)
        ).pipe(
            take(1),
            tap((response: ServerResponseInterface<T>) => {
                if (response.message) {
                    this.storeWrapper.showToast(response.message);
                }
            }),
            map(({ status, data }: ServerResponseInterface<T>) => {
                if (status && data) {
                    return data;
                } else if (options?.defaultValue) {
                    return options.defaultValue;
                }

                return (undefined as any as T);
            }),
            catchError(error => {
                this.storeWrapper.showToast(error.message);

                if (options?.defaultValue) {
                    return of(options.defaultValue);
                } else {
                    return of(undefined) as any as Observable<T>;
                }
            }),
        );
    }
}
