import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UnreadMessagesPipe } from './unread-messages.pipe';

@NgModule({
    declarations: [UnreadMessagesPipe],
    imports: [CommonModule],
    exports: [UnreadMessagesPipe],
})
export class UnreadMessagesModule { }
