<div *ngFor="let menuItem of menuItems$ | async">
    <ng-container *ngTemplateOutlet="!!menuItem.submenu ? multiLevel : singleLevel; context: { menuItem: menuItem }"></ng-container>
</div>

<ng-template #singleLevel let-menuItem="menuItem">
    <ion-menu-toggle [autoHide]="false">
        <ion-item
            *ngIf="isShowMenuItem(menuItem)"
            [routerLink]="menuItem.url"
            [routerLinkActive]="'selected'"
            routerDirection="root"
            lines="none"
            detail="false"
        >
            <ion-label>
                <div>
                    {{ menuItem.title }}
                </div>
                <div class="view-status">
                    {{ menuItem.subTitle }}
                </div>
            </ion-label>
        </ion-item>
    </ion-menu-toggle>
</ng-template>

<ng-template #multiLevel let-menuItem="menuItem">
    <ion-item *ngIf="isShowMenuItem(menuItem)" (click)="menuItem.isOpen = !menuItem.isOpen" lines="none">
        <ion-label>
            {{ menuItem.title }}
        </ion-label>
        <ion-icon slot="end" [name]="menuItem.isOpen ? 'chevron-down-outline' : 'chevron-forward-outline'"></ion-icon>
    </ion-item>
    <ion-list *ngIf="menuItem.isOpen" class="sub-menu">
        <ion-menu-toggle *ngFor="let s of menuItem.submenu" [autoHide]="false">
            <ion-item
                *ngIf="isShowMenuItem(s)"
                [routerLink]="s.url"
                [routerLinkActive]="'selected'"
                routerDirection="root"
                lines="none"
                detail="false"
            >
                <ion-label>{{ s.title }}</ion-label>
            </ion-item>
        </ion-menu-toggle>
    </ion-list>
</ng-template>
