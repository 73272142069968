import { FcmMessageDataInterface, StoreWrapperInterface, STORE_WRAPPER_TOKEN } from '@actassa/api';
import { Inject, Injectable } from '@angular/core';
import { NavController } from '@ionic/angular';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { Select } from '@ngxs/store';
import { isEmpty, isEqual } from 'lodash-es';
import { combineLatest, Observable, zip } from 'rxjs';
import { take, tap, mapTo, filter, switchMap, map, distinctUntilChanged } from 'rxjs/operators';
import { PickInspection } from '../+state/actions/pick-inspection';
import { DataCollectionState } from '../+state/data-collection.state';
import { RoutesDictionary } from '../dictionaries/routes.dictionary';
import { FCMPushDataActions } from '../enums/fcm-push-data-actions.enum';
import { InspectionInterface } from '../interfaces/inspection.interface';
import { InspectionsService } from './inspections.service';

type pushHelper = (data: FcmMessageDataInterface) => void;

@Injectable({
    providedIn: 'root',
})
export class PushHandlerService {
    @Select(DataCollectionState.inspections$) public dataCollections$: Observable<Array<InspectionInterface>>;

    private generatePushHelper: Record<FCMPushDataActions, pushHelper> = {
        [FCMPushDataActions.NEW_DATA_COLLECTION]: this.dataCollection,
    };

    constructor(
        @Inject(STORE_WRAPPER_TOKEN) private storeWrapper: StoreWrapperInterface,
        private readonly dataCollectionService: InspectionsService,
        private readonly navigationController: NavController,
    ) { }

    public init$(): Observable<void> {
        return combineLatest([
            this.storeWrapper.notificationData$,
            this.storeWrapper.isAppStateActive$,
        ])
            .pipe(
                filter(([data, isAppActive]: [FcmMessageDataInterface, boolean]) => !isEmpty(data) && isAppActive),
                map(([data]: [FcmMessageDataInterface, boolean]) => data),
                distinctUntilChanged(isEqual),
                switchMap((data: FcmMessageDataInterface) => zip(
                    this.dataCollectionService.load$(),
                ).pipe(
                    tap(() => this.handle(data)),
                    mapTo(undefined),
                )),
            );
    }

    private handle(data: FcmMessageDataInterface) {
        const handlerFunction: pushHelper = this.generatePushHelper[data.action];

        if (!handlerFunction) {
            this.storeWrapper.loadingEnd();

            return;
        }

        this.storeWrapper.clearNotificationData();

        return handlerFunction.call(this, data);
    }

    private dataCollection({ id }: FcmMessageDataInterface): void {
        this.dataCollections$
            .pipe(take(1))
            .subscribe((inspections: Array<InspectionInterface>) => {
                const inspection = inspections.find((i: InspectionInterface) => i.id === id);

                if (!inspection) {
                    this.gotoAppPage(RoutesDictionary.DATA_COLLECTION_INSPECTIONS);

                    return;
                }

                this.storeWrapper.loadingEnd();
                this.pickInspection(inspection);
                this.gotoAppPage(RoutesDictionary.DATA_COLLECTION_INSPECTION);
            });
    }

    @Dispatch()
    private pickInspection(inspection: InspectionInterface): PickInspection {
        return new PickInspection(inspection.id);
    }

    private gotoAppPage(path: string): void {
        this.storeWrapper.baseUrl$
            .pipe(take(1))
            .subscribe((baseUrl: string) => {
                this.navigationController.navigateRoot(`${baseUrl}/${path}`);
            });
    }
}
