import { MainMenuItemInterface } from '@actassa/api';
import { MenuTitlesDictionary } from '../dictionaries/menu-titles.dictionary';
import { RoutesDictionary } from '../dictionaries/routes.dictionary';
import { SectionsEnum } from '../enums/sections.enum';

export const DataCollectionMenu: MainMenuItemInterface = {
    title: MenuTitlesDictionary.DATA_COLLECTION_INSPECTIONS,
    url: RoutesDictionary.DATA_COLLECTION_INSPECTIONS,
    isActive: true,
    key: SectionsEnum.DATA_COLLECTION_INSPECTIONS,
    icon: 'reader-outline',
};

export const DataCollectionSurveyMenu: MainMenuItemInterface = {
    title: MenuTitlesDictionary.DATA_COLLECTION_SURVEYS,
    url: RoutesDictionary.DATA_COLLECTION_SURVEYS,
    isActive: false,
    key: SectionsEnum.DATA_COLLECTION_SURVEYS,
    icon: 'newspaper-outline',
};

export const DataCollectionFormsMenu: MainMenuItemInterface = {
    title: MenuTitlesDictionary.DATA_COLLECTION_FORMS,
    url: RoutesDictionary.DATA_COLLECTION_FORMS,
    isActive: false,
    key: SectionsEnum.DATA_COLLECTION_FORMS,
    icon: 'reader-outline',
};
