export enum JobOpportunitiesButtonTitlesEnum {
    APPLY_ALL = 'apply_all',
    APPLY_SOME = 'apply_some',
    DECLINE = 'decline',
    LET_THINK = 'let_think',

    // cherry pick
    APPLY_FOR_SELECTED_SHIFT = 'apply_for_selected_shift',
    UPDATE_MY_RESPONSE = 'update_my_response',
    DECLINE_ALL_SHIFTS = 'decline_all_shifts',
}
