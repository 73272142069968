import { DictionaryInterface, StoreWrapperInterface, STORE_WRAPPER_TOKEN } from '@actassa/api';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class PageTitleService {
    constructor(
        @Inject(STORE_WRAPPER_TOKEN) private storeWrapper: StoreWrapperInterface,
    ) { }

    public getPageTitle$(key: string, defaultsDictionary: DictionaryInterface<string>): Observable<string> {
        const defaultPageTitle = defaultsDictionary[key] || '';

        return this.storeWrapper.getPageTitle$(key)
            .pipe(
                map(pageTitle => pageTitle || defaultPageTitle),
                startWith(defaultPageTitle),
            );
    }
}
