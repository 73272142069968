import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MAIN_PAGE } from './constants/constants';
import { DataCollectionComponent } from './data-collection.component';

const healthSafetyRoutes: Routes = [
    {
        path: '',
        component: DataCollectionComponent,
        children: [
            {
                path: '',
                redirectTo: MAIN_PAGE,
                pathMatch: 'full',
            },
            {
                path: 'inspection',
                // canActivate: [AuthGuard],
                loadChildren: () =>
                    import('./pages/inspection/inspection.module').then((m) => m.InspectionPageModule),
                // resolve: { message: JobsLoaderResolver }
            },
            {
                path: 'inspections',
                // canActivate: [AuthGuard],
                loadChildren: () =>
                    import('./pages/inspections/inspections.module').then((m) => m.InspectionsPageModule),
                // resolve: { message: JobsLoaderResolver }
            },
            {
                path: 'surveys',
                loadChildren: () => import('./pages/surveys/surveys.module').then((m) => m.SurveysModule),
            },
            {
                path: 'forms',
                loadChildren: () => import('./pages/common-forms/common-forms.module').then((m) => m.CommonFormsModule),
            },

        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(healthSafetyRoutes)],
    exports: [RouterModule],
})
export class DataCollectionRoutingModule { }
