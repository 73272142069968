import { ChangeDetectionStrategy, Component, OnDestroy, OnInit } from '@angular/core';
import { Dispatch } from '@ngxs-labs/dispatch-decorator';
import { merge, Subscription } from 'rxjs';
import { LoadTemplates } from './+state/actions/load-templates';
import { InspectionsService } from './services/inspections.service';
import { TemplatesService } from './services/templates.service';

@Component({
    selector: 'actassa-data-collection',
    templateUrl: './data-collection.component.html',
    styleUrls: ['./data-collection.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DataCollectionComponent implements OnInit, OnDestroy {
    private subscriptions = new Subscription();

    constructor(
        private readonly inspectionsService: InspectionsService,
        private readonly templatesService: TemplatesService,
    ) { }

    public ngOnInit(): void {
        const subscription = merge(
            this.inspectionsService.init$(),
            this.templatesService.init$(),
        ).subscribe();

        this.subscriptions.add(subscription);

        this.loadTemplates();
    }

    public ngOnDestroy(): void {
        this.subscriptions.unsubscribe();
    }

    @Dispatch()
    private loadTemplates(): LoadTemplates {
        return new LoadTemplates();
    }
}
