import {
    JobEmploymentTypeInterface,
} from '@actassa/api';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseApiService } from './base.api.service';

@Injectable({
    providedIn: 'root',
})
export class JobEmploymentTypesApiService {

    constructor(
        private readonly baseApiService: BaseApiService,
    ) { }

    public getAll$(): Observable<Array<JobEmploymentTypeInterface>> {
        return this.baseApiService.get$<Array<JobEmploymentTypeInterface>>(
            'job-api/employment-type',
            { defaultValue: [] },
        );
    }
}
