import { StoreWrapperInterface, STORE_WRAPPER_TOKEN } from '@actassa/api';
import { Component, OnInit, ChangeDetectionStrategy, Input, Inject } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DataCollectionState } from '../../+state/data-collection.state';
import { TemplateInterface } from '../../interfaces/template.interface';
import { InspectionType } from '../../types/inspection-type';

const DEFAULT_DATA_COLLECTION_TYPE: InspectionType = 'form';

@Component({
    selector: 'actassa-form-template-modal',
    templateUrl: './form-template-modal.component.html',
    styleUrls: ['./form-template-modal.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormTemplateModalComponent implements OnInit {
    @Select(DataCollectionState.templates$) private _templates$: Observable<Array<TemplateInterface>>;

    @Input() public dataCollectionType: InspectionType = DEFAULT_DATA_COLLECTION_TYPE;

    public templates$: Observable<Array<TemplateInterface>>;

    constructor(
        @Inject(STORE_WRAPPER_TOKEN) private readonly mainStore: StoreWrapperInterface,
        private readonly modalController: ModalController) { }

    public ngOnInit(): void {
        this.templates$ = this._templates$.pipe(
            map((templates: Array<TemplateInterface>) => templates
                .filter((template) => template.type === this.dataCollectionType
                    && template.application === this.mainStore.app
                    && template.providerID === this.mainStore.providerId)),
        );
    }

    public select(data: TemplateInterface): void {
        this.modalController.dismiss({ ...data });
    }

    public cancel(): void {
        this.modalController.dismiss();
    }
}
