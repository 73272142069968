import { ClientSectionsEnum } from '../enums/client-sections.enum';

export const ClientMenuTitlesDictionary: Record<ClientSectionsEnum, string> = {
    [ClientSectionsEnum.AUTH]: 'Auth',
    [ClientSectionsEnum.DATA_COLLECTION]: 'Data collection',
    [ClientSectionsEnum.HELP]: 'Help',
    [ClientSectionsEnum.HOME]: 'Home',
    [ClientSectionsEnum.LOGOUT]: 'Logout',
    [ClientSectionsEnum.MESSAGING]: 'Messaging',
    [ClientSectionsEnum.SELECT_APPLICATION]: 'Select application',
    [ClientSectionsEnum.SETTINGS]: 'Account & Settings',
};
