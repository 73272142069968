import { JobInterface, JobsSearchRequestInterface } from '@actassa/api';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { take, tap } from 'rxjs/operators';
import { JobsApiService } from '../api/jobs.api.service';

@Injectable({
    providedIn: 'root',
})
export class JobsRepoService {

    private _myJobs$ = new BehaviorSubject<Array<JobInterface> | undefined>(undefined);
    private _searchJobsResult$ = new BehaviorSubject<Array<JobInterface> | undefined>(undefined);

    constructor(
        private readonly jobsApiService: JobsApiService,
    ) { }

    public get myJobs$(): Observable<Array<JobInterface> | undefined> {
        return this._myJobs$.asObservable();
    }

    public get jobsSearchResult$(): Observable<Array<JobInterface> | undefined> {
        return this._searchJobsResult$.asObservable();
    }

    public loadMyJobs$(): Observable<Array<JobInterface>> {
        return this.jobsApiService
            .getMy$()
            .pipe(
                take(1),
                tap(myJobs => this._myJobs$.next(this.sortJobs(myJobs))),
            );
    }

    public searchJobs$(
        filter?: JobsSearchRequestInterface,
    ): Observable<Array<JobInterface>> {
        return this.jobsApiService
            .search$(filter)
            .pipe(
                take(1),
                tap(searchJobsResult => this._searchJobsResult$.next(this.sortJobs(searchJobsResult))),
            );
    }

    public clearSearch(): void {
        this._searchJobsResult$.next(undefined);
    }

    private sortJobs(jobs: Array<JobInterface>): Array<JobInterface> {
        return jobs.sort((a: JobInterface, b: JobInterface) => (b.JobId || 0) - (a.JobId || 0));
    }
}
