// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import versionConfig from '../version.json';

export const environment = {
    production: false,
    apiURL: 'https://trndev.actassa.com/mobile-dev',
    botURL: 'wss://bots.activeassociate.com/dev/webchat001/',
    firebase: {
        apiKey: 'AIzaSyChc4jB2piBKeHLEGjNH3ynnz5V9jYcqnk',
        authDomain: 'trn-mobile.firebaseapp.com',
        databaseURL: 'https://trn-mobile.firebaseio.com',
        projectId: 'trn-mobile',
        storageBucket: 'trn-mobile.appspot.com',
        messagingSenderId: '1047540316916',
        appId: '1:1047540316916:web:2b5cb2181da5cd35883ca1',
        measurementId: 'G-GCTCJNLZD4',
    },
    socketIoConfig: {
        url: 'https://trndev.actassa.com:3006',
        options: {
            autoConnect: false,
        },
    },
    appVersion: versionConfig.version,
    appId: 'com.actassa.trnmobile',
    appleAppId: '1512574387',
    appName: 'TRN mobile',
    platform: 'web',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
